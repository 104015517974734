import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    root: String,
    rootMargin: { type: String, default: '-1px 0px 0px 0px' },
    stuckClass: { type: String, default: '--stuck' },
    dispatchEvents: { type: Boolean, default: true }
  }

  connect () {
    this.observer = new IntersectionObserver(this._handleIntersect.bind(this), this.observerOptions)
    this.observer.observe(this.element)
  }

  disconnect () {
    this.observer.disconnect()
  }

  get observerOptions () {
    return {
      root: this.hasRootValue ? document.querySelector(this.rootValue) : null,
      rootMargin: this.rootMarginValue,
      threshold: 1.0
    }
  }

  _handleIntersect (entries) {
    entries.forEach((entry) => {
      const isStuck = !entry.isIntersecting
      this._toggleStuckState(isStuck)
    })
  }

  _toggleStuckState (isStuck) {
    this.element.classList.toggle(this.stuckClassValue, isStuck)
    if (this.dispatchEventsValue) {
      const eventName = isStuck ? 'stuck' : 'unstuck'
      this.element.dispatchEvent(new CustomEvent(eventName, { bubbles: true }))
    }
  }
}
